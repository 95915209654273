import * as React from "react"
import { FC } from "react"
import {
  Edit,
  SimpleForm,
  TextInput,
  EditProps,
  SelectInput,
} from "react-admin"
import { makeStyles } from "@material-ui/core"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  id: { display: "inline-block" },
  slug: { display: "inline-block", marginLeft: 32 },
}

const useStyles = makeStyles(styles)

const OrderItemTitle = ({ record }: any) => (
  <span>Edit OrderItem {record && `#${record.id}`}</span>
)

const condition = (val: string) => !!val && val.trim().length > 5

export const OrderItemEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<OrderItemTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.orderItems.fieldGroups.information" />
        <TextInput disabled source="id" formClassName={classes.id} />
        <SelectInput
          source="status"
          choices={[
            { id: "pending", name: "Pending" },
            { id: "approve", name: "Approve" },
            { id: "pickup", name: "Pickup" },
          ]}
        />
      </SimpleForm>
    </Edit>
  )
}
