import * as React from "react"
import { Button, NumberInput, PasswordInput, useNotify } from "react-admin"
import { Form } from "react-final-form"
import { Box } from "@material-ui/core"
import { useHistory } from "react-router-dom"

const asyncPost = async (url: string, data: any) => {
  const request = new Request(url, {
    method: "POST",
    body: JSON.stringify(data),

    headers: new Headers({ "Content-Type": "application/json" }),
  })
  const res = await fetch(request)
  if (res.ok) {
    return { data: res.json() }
  }
  const { errors } = await res.json()
  return { error: errors[0].message }
}

const ForgetPassword = () => {
  const [step, setStep] = React.useState(1)
  const [phone, setPhone] = React.useState("")
  const [code, setCode] = React.useState("")
  const history = useHistory()
  const notify = useNotify()

  const handleSubmit = (values: any) => {
    if (step === 1) {
      asyncPost("/api/users/send/resetcode", values).then((res) => {
        if (res.data) {
          setPhone(values.phone)
          setStep(2)
        } else if (res.error) {
          notify(res.error, "warning")
        }
      })
    }
    if (step === 2) {
      asyncPost("/api/users/verify/resetcode", { ...values, phone }).then(
        (res) => {
          if (res.data) {
            setCode(values.code)
            setStep(3)
          } else if (res.error) {
            notify(res.error, "warning")
          }
        }
      )
    }
    if (step === 3) {
      asyncPost("/api/users/reset/password", { ...values, phone, code }).then(
        (res) => {
          if (res.data) {
            notify("Password changed successfully", "success")
            history.push("/login")
          } else if (res.error) {
            notify(res.error, "warning")
          }
        }
      )
    }
  }

  return (
    <div>
      {step === 1 && (
        <div>
          <Form
            onSubmit={handleSubmit}
            render={(formProps) => {
              return (
                <form onSubmit={formProps.handleSubmit}>
                  <Box>
                    <NumberInput source="phone" />
                  </Box>
                  <Box>
                    <Button
                      label="Send Code"
                      size="large"
                      onClick={formProps.handleSubmit}
                      variant="contained"
                      disabled={formProps.submitting || formProps.pristine}
                    />
                  </Box>
                </form>
              )
            }}
          />
        </div>
      )}
      {step === 2 && (
        <div>
          <Form
            onSubmit={handleSubmit}
            render={(formProps) => {
              return (
                <form onSubmit={formProps.handleSubmit}>
                  <Box>
                    <NumberInput source="code" />
                  </Box>
                  <Box>
                    <Button
                      label="Verify Code"
                      size="large"
                      onClick={formProps.handleSubmit}
                      variant="contained"
                      disabled={formProps.submitting || formProps.pristine}
                    />
                  </Box>
                </form>
              )
            }}
          />
        </div>
      )}
      {step === 3 && (
        <div>
          <Form
            onSubmit={handleSubmit}
            render={(formProps) => {
              return (
                <form onSubmit={formProps.handleSubmit}>
                  <Box>
                    <PasswordInput source="password" />
                  </Box>
                  <Box>
                    <Button
                      label="Change Password"
                      size="large"
                      onClick={formProps.handleSubmit}
                      variant="contained"
                      disabled={formProps.submitting || formProps.pristine}
                    />
                  </Box>
                </form>
              )
            }}
          />
        </div>
      )}
    </div>
  )
}

export default ForgetPassword
