import * as React from "react"
import { FC, useCallback, useState, useEffect } from "react"
import {
  Edit,
  EditProps,
  useMutation,
  TabbedForm,
  FormTab,
  TextInput,
  ImageInput,
  required,
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteInput,
  SelectInput,
  AutocompleteArrayInput,
  NumberInput,
  minValue,
  BooleanInput,
  useGetOne,
  useGetList,
  useNotify,
  useRefresh,
  useDataProvider,
  useVersion,
  useGetIdentity,
} from "react-admin"
import { useFormState } from "react-final-form"
import RichTextInput from "ra-input-rich-text"
import { FieldArray } from "react-final-form-arrays"
import { Box, makeStyles, Button, IconButton } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import Typography from "@material-ui/core/Typography"
import DeleteIcon from "@material-ui/icons/Delete"
import { SectionTitle, Separator } from "../../common/form"

import ProductImageField from "./ProductImageField"
import VariationImageInput from "./VariationImageInput"
import TagReferenceInput from "./TagReferenceInput"
import SeoKeywordInput from "../../common/form/SeoKeywordInput"

const Aside = ({ record }: any) => (
  <div style={{ width: 200, margin: "1em" }}>
    <Typography variant="h6">Product details</Typography>
    {record && (
      <Typography variant="body2">Creation date: {record.createdAt}</Typography>
    )}
  </div>
)

const styles = {
  firstName: { width: 544 },
  lastName: { width: 544 },
  title: { width: 544 },
  slug: { width: 544 },
  sku: { width: 544 },
  shortDescription: { width: 544 },
  description: { width: 544 },
  image: { width: 544 },
  boolean: { display: "inline-block" },
  brand: { display: "inline-block", width: 252 },
  productType: { display: "inline-block", marginLeft: 32, width: 262 },
  categories: { width: 544 },
  tags: { width: 544 },
  productBy: { width: 544 },
  weight: { display: "inline-block" },
  dimension: { display: "inline-block", marginLeft: 32 },
  quantity: { display: "block" },
  media: { width: 544 },
  minOrder: { width: 262 },
  shippingClass: { display: "inline-block" },
  shippingValue: { display: "inline-block", marginLeft: 32 },
  attributeSelect: { width: 100 },
  relatedProducts: { width: 544 },
  metaTitle: { width: 544 },
  metaDescription: { width: 544 },
}

const useStyles = makeStyles(styles)

const ProductTitle = ({ record }: any) => (
  <span>Product {record ? `"${record.title}"` : `#${record.id}`}</span>
)

const condition = (val: string) => !!val && val.trim().length > 6

interface RenderMarginInputProps {
  name: string
}

interface State {
  margin?: number
}

const RenderMarginInput: FC<RenderMarginInputProps> = ({ name }: any) => {
  const [state, setState] = useState<State>({})
  const version = useVersion()
  const { values } = useFormState()
  const { identity, loading: identityLoading } = useGetIdentity()

  useEffect(() => {
    if (identity)
      setState((state) => ({
        ...state,
        margin: identity.vendorProps.globalMargin,
      }))
  }, [identity])
  const { margin } = state

  if (values.marginType === "global")
    return margin ? (
      <span>
        Vendor has global margin: <strong>{margin} %</strong>
      </span>
    ) : (
      <span>
        Please set global margin for vendor <strong>{values.productBy}</strong>{" "}
      </span>
    )

  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <Box width={544}>
          {fields.map((name, index) => (
            <div key={name}>
              <Typography variant="subtitle2">
                {`Margin #${index + 1}`}
              </Typography>
              <Box display="flex" mb="0.5em">
                <Box flex={1} mr="0.5em">
                  <NumberInput
                    source={`${name}.from`}
                    label="Margin From"
                    validate={required()}
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr="0.5em">
                  <NumberInput
                    source={`${name}.to`}
                    type="number"
                    label="Margin To"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr="0.5em">
                  <NumberInput
                    source={`${name}.value`}
                    label={
                      values?.marginType
                        ? values.marginType.replace(/\b\w/g, (l: string) =>
                            l.toUpperCase()
                          )
                        : "Percentage/Rupees"
                    }
                    validate={required()}
                    fullWidth
                  />
                </Box>

                <IconButton type="button" onClick={() => fields.remove(index)}>
                  <RemoveIcon />
                </IconButton>
              </Box>
            </div>
          ))}
          <Button
            onClick={() =>
              fields.push({
                from: "",
                to: "",
                value: "",
              })
            }
          >
            <AddIcon /> Add Margin
          </Button>
        </Box>
      )}
    </FieldArray>
  )
}

interface RenderPricingInputProps {
  name: string
  combinationIndex: number
}

const RenderPricingInput: FC<RenderPricingInputProps> = ({
  name,
  combinationIndex,
}) => {
  const [state, setState] = useState<State>({})
  const version = useVersion()
  const { values } = useFormState()
  const dataProvider = useDataProvider()
  const { identity, loading: identityLoading } = useGetIdentity()

  useEffect(() => {
    if (identity)
      setState((state) => ({
        ...state,
        margin: identity?.vendorProps?.globalMargin,
      }))
  }, [identity])

  const priceFieldGenerator = () => {
    if (!values.hasVariation) {
      for (const priceIndex in values.price) {
        if (!values.isSp) {
          if (values.marginType === "percentage")
            values.price[priceIndex].price.NRS =
              values.price[priceIndex].margin *
                0.01 *
                values.price[priceIndex].cp +
              values.price[priceIndex].cp
          if (values.marginType === "rupees")
            values.price[priceIndex].price.NRS =
              values.price[priceIndex].cp + values.price[priceIndex].margin
          if (values.marginType === "global" && margin)
            values.price[priceIndex].price.NRS =
              margin * 0.01 * values.price[priceIndex].cp +
              values.price[priceIndex].cp
        } else {
          values.price[priceIndex].price.NRS = values.price[priceIndex].sp
        }
      }
    } else {
      for (const priceIndex in values.variation.combination[combinationIndex]
        .price) {
        if (!values.isSp) {
          if (values.marginType === "percentage")
            values.variation.combination[combinationIndex].price[
              priceIndex
            ].price.NRS =
              values.variation.combination[combinationIndex].price[priceIndex]
                .margin *
                0.01 *
                values.variation.combination[combinationIndex].price[priceIndex]
                  .cp +
              values.variation.combination[combinationIndex].price[priceIndex]
                .cp
          if (values.marginType === "rupees")
            values.variation.combination[combinationIndex].price[
              priceIndex
            ].price.NRS =
              values.variation.combination[combinationIndex].price[priceIndex]
                .cp +
              values.variation.combination[combinationIndex].price[priceIndex]
                .margin
          if (values.marginType === "global" && margin)
            values.variation.combination[combinationIndex].price[
              priceIndex
            ].price.NRS =
              margin *
                0.01 *
                values.variation.combination[combinationIndex].price[priceIndex]
                  .cp +
              values.variation.combination[combinationIndex].price[priceIndex]
                .cp
        } else {
          values.variation.combination[combinationIndex].price[
            priceIndex
          ].price.NRS =
            values.variation.combination[combinationIndex].price[priceIndex].sp
        }
      }
    }
  }

  useEffect(() => {
    priceFieldGenerator()
  }, [version, values.price, values.variation])

  const { margin } = state

  return (
    <div>
      {values.marginType === "global" ? (
        margin ? (
          <Typography variant="body1">
            Vendor has global margin: <strong>{margin} %</strong>
          </Typography>
        ) : (
          <Typography variant="body1">
            Please set global margin for vendor{" "}
            <strong>{values.productBy}</strong>{" "}
          </Typography>
        )
      ) : null}
      <FieldArray name={name}>
        {({ fields }) => (
          <Box width={544}>
            {fields.map((name, index) => (
              <div key={name} style={{ marginBottom: "10px" }}>
                <Typography variant="subtitle2">
                  {`Pricing #${index + 1}`}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Box flex={1} mr="0.5em">
                    <NumberInput
                      source={`${name}.quantity.from`}
                      type="number"
                      label="Quantity From"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} mr="0.5em">
                    <NumberInput
                      source={`${name}.quantity.to`}
                      type="number"
                      label="Quantity To"
                      fullWidth
                    />
                  </Box>
                  {!values.isSp && (
                    <Box flex={1} mr="0.5em">
                      <NumberInput
                        source={`${name}.cp`}
                        type="number"
                        label="Cost Price"
                        fullWidth
                      />
                    </Box>
                  )}
                  {values.isSp && (
                    <Box flex={1} mr="0.5em">
                      <NumberInput
                        source={`${name}.sp`}
                        type="number"
                        label="Selling Price"
                        fullWidth
                      />
                    </Box>
                  )}
                  {values.marginType !== "global" && (
                    <Box flex={1} mr="0.5em">
                      <NumberInput
                        source={`${name}.margin`}
                        label={
                          values?.marginType
                            ? values.marginType.replace(
                                /\b\w/g,
                                (l: string) => "Margin" + " " + l.toUpperCase()
                              )
                            : "Percentage/Rupees"
                        }
                        validate={required()}
                        fullWidth
                      />
                    </Box>
                  )}

                  <IconButton
                    type="button"
                    onClick={() => fields.remove(index)}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Box>
                <Typography variant="body1">
                  {!values.hasVariation ? (
                    <div>
                      Showing Price ={" "}
                      <strong>
                        Rs. {}
                        {!values.isSp
                          ? values.price[index].cp
                            ? values.marginType === "percentage"
                              ? values.price[index].margin *
                                  0.01 *
                                  values.price[index].cp +
                                values.price[index].cp
                              : values.marginType === "rupees"
                              ? values.price[index].cp +
                                values.price[index].margin
                              : values.marginType === "global" && margin
                              ? margin * 0.01 * values.price[index].cp +
                                values.price[index].cp
                              : "Set vendor flat margin"
                            : null
                          : values.price[index].sp}
                      </strong>
                    </div>
                  ) : (
                    <div>
                      Showing Price ={" "}
                      <strong>
                        Rs.{" "}
                        {!values.isSp
                          ? values.variation.combination[combinationIndex]
                              .price[index].cp
                            ? values.marginType === "percentage"
                              ? values.variation.combination[combinationIndex]
                                  .price[index].margin *
                                  0.01 *
                                  values.variation.combination[combinationIndex]
                                    .price[index].cp +
                                values.variation.combination[combinationIndex]
                                  .price[index].cp
                              : values.marginType === "rupees"
                              ? values.variation.combination[combinationIndex]
                                  .price[index].cp +
                                values.variation.combination[combinationIndex]
                                  .price[index].margin
                              : values.marginType === "global" && margin
                              ? margin *
                                  0.01 *
                                  values.variation.combination[combinationIndex]
                                    .price[index].cp +
                                values.variation.combination[combinationIndex]
                                  .price[index].cp
                              : "Set vendor flat margin"
                            : null
                          : values.variation.combination[combinationIndex]
                              .price[index].sp}
                      </strong>
                    </div>
                  )}
                </Typography>
              </div>
            ))}
            <Button
              onClick={() =>
                fields.push({
                  quantity: { from: "", to: "" },
                  price: { NRS: "", USD: "" },
                })
              }
            >
              <AddIcon /> Add Price
            </Button>
          </Box>
        )}
      </FieldArray>
    </div>
  )
}

interface RenderPricingNoVariationInputProps {
  name: string
}

const RenderPricingNoVariationInput: FC<RenderPricingNoVariationInputProps> = ({
  name,
}) => {
  const state = useFormState()
  return !state.values.hasVariation ? (
    <>
      <Box flex={1} mr="0.5em" mb="0.5em">
        <NumberInput source="stock" />
      </Box>
      <RenderPricingInput name={name} combinationIndex={0} />
    </>
  ) : null
}

interface RenderAttributeSelectProps {
  id: string
  name: string
  index: number
}

const RenderAttributeSelect: FC<RenderAttributeSelectProps> = ({
  id,
  name,
  index,
}) => {
  const { data, loading, error } = useGetOne("products/attributes", id)
  if (loading) {
    return <div>Loading ...</div>
  }
  if (error) {
    return <div>Erroring...</div>
  }

  const choices = data?.values.map((value: any) => ({
    id: value._id,
    name: value.title,
  }))

  return data ? (
    <SelectInput
      source={`${name}.conf.level${index}`}
      label={`Select ${data?.title}`}
      choices={choices}
    />
  ) : null
}

const CombinationInput = (props: any) => {
  const { values } = useFormState()

  return (
    <FieldArray name="variation.combination">
      {({ fields }) => (
        <Box width={544}>
          <Typography variant="subtitle1" gutterBottom>
            Variations
          </Typography>
          {fields.map((name, index) => (
            <Box
              key={name}
              border={2}
              borderColor="#eceff1"
              borderRadius={10}
              pl="0.5em"
              mb="0.5em"
            >
              <Box display="flex">
                <Box flexGrow={1} mt="0.5em">
                  <Typography variant="subtitle1" gutterBottom>
                    {`Variation #${index + 1}`}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => fields.remove(index)}
                    style={{ color: "#f44336" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
              {values && values.variation && values.variation.levelName && (
                <Box display="flex" style={{ width: 544 }}>
                  {values.variation.levelName.map(
                    (id: string, indexer: number) => (
                      <Box
                        flex={1}
                        mr="0.5em"
                        pr="0.8em"
                        style={{ width: 100 }}
                      >
                        <RenderAttributeSelect
                          name={name}
                          id={id}
                          index={indexer + 1}
                        />
                      </Box>
                    )
                  )}
                </Box>
              )}

              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <TextInput source={`${name}.title`} label="Title" fullWidth />
                </Box>
                <Box flex={1} mr="0.5em">
                  <NumberInput
                    source={`${name}.stock`}
                    label="Available Quantity"
                    fullWidth
                  />
                </Box>
              </Box>
              <VariationImageInput
                name={`${name}.media`}
                combinationIndex={index}
              />
              <RenderPricingInput
                name={`${name}.price`}
                combinationIndex={index}
              />
            </Box>
          ))}
          <Button
            onClick={() => fields.push({ stock: "", title: "", sku: "" })}
          >
            <AddIcon /> variation
          </Button>
        </Box>
      )}
    </FieldArray>
  )
}

interface VariationInputProps {
  attributeIds: any
  attributeData: any
}

const VariationInput: FC<VariationInputProps> = ({
  attributeIds,
  attributeData,
}) => {
  const classes = useStyles()
  const { values } = useFormState()
  const choices = attributeIds?.map((id: any) => ({
    id,
    name: attributeData[id].title,
  }))

  const handleClick = (fields, index) => {
    for (const combIndex in values.variation.combination) {
      delete values.variation.combination[combIndex].conf[`level${index + 1}`]
    }
    fields.remove(index)
  }
  return values.hasVariation ? (
    <>
      <SectionTitle label="resources.products.fieldGroups.attributes" />

      <Typography variant="subtitle1" gutterBottom>
        Add and Select Attributes
      </Typography>

      <FieldArray name="variation.levelName">
        {({ fields }) => (
          <Box display="flex" mt="1em" style={{ width: 544 }}>
            {fields.map((name, index) => (
              <div key={name}>
                <Box flex={1} mr="0.5em">
                  <SelectInput
                    source={`${name}`}
                    label={`Level #${index + 1}`}
                    choices={choices}
                    fullWidth
                    formclassName={classes.attributeSelect}
                  />
                </Box>

                <IconButton onClick={() => handleClick(fields, index)}>
                  <RemoveIcon />
                </IconButton>
              </div>
            ))}
            <Button onClick={() => fields.push("")}>
              <AddIcon />
            </Button>
          </Box>
        )}
      </FieldArray>
      <CombinationInput />
    </>
  ) : null
}

const ProductEdit: FC<EditProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()

  const {
    data: attributeData,
    ids: attributeIds,
    loading: attributeLoading,
    error: attributeError,
  } = useGetList(
    "products/attributes",
    { page: 1, perPage: 100 },
    { field: "title", order: "ASC" }
  )

  const [mutate] = useMutation()
  const save = useCallback(
    async (values) => {
      try {
        await mutate(
          {
            type: "update",
            resource: "products/products",
            payload: { id: values.id, data: values },
          },
          { returnPromise: true }
        )
        notify("Product updated successfully.")
        refresh()
      } catch (error: any) {
        let err: any = {}
        for (let e of error.body.errors) {
          e.map((e: any) => {
            err[e.field] = e.message
          })
        }
        return err
      }
    },
    [mutate]
  )
  const productDefaultValue = () => ({
    meta: {
      isApproved: false,
    },
  })
  return (
    <Edit title={<ProductTitle />} {...props}>
      <TabbedForm initialValues={productDefaultValue} save={save}>
        <FormTab label="main">
          <TextInput
            source="title"
            validate={required()}
            fullWidth
            formClassName={classes.title}
          />
          <TextInput source="slug" fullWidth formClassName={classes.slug} />
          <TextInput source="sku" fullWidth formClassName={classes.sku} />
          <RichTextInput
            source="shortDescription"
            fullWidth
            formClassName={classes.shortDescription}
            toolbar={[
              ["bold", "italic", "underline", "link"],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ font: [] }],
              [{ align: [] }],
              ["image"],
              [
                "table",
                "column-left",
                "column-right",
                "row-above",
                "row-below",
                "row-remove",
                "column-remove",
              ],
            ]}
          />
          <RichTextInput
            source="description"
            fullWidth
            formClassName={classes.description}
            toolbar={[
              ["bold", "italic", "underline", "link"],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ font: [] }],
              [{ align: [] }],
              ["image"],
              [
                "table",
                "column-left",
                "column-right",
                "row-above",
                "row-below",
                "row-remove",
                "column-remove",
              ],
            ]}
          />
        </FormTab>
        <FormTab label="meta">
          <ReferenceInput
            source="brand"
            reference="products/brands"
            sort={{ field: "title", order: "ASC" }}
            perPage={1000}
            filterToQuery={(val: string) =>
              condition(val) ? { title: val.trim() } : {}
            }
            fullWidth
            allowEmpty
            formClassName={classes.brand}
          >
            <AutocompleteInput optionText="title" optionValue="id" />
          </ReferenceInput>
          <SelectInput
            source="productType"
            choices={[
              { id: "software", name: "Software" },
              { id: "hardware", name: "Hardware" },
            ]}
            fullWidth
            allowEmpty
            formClassName={classes.productType}
          />
          <ReferenceArrayInput
            label="Categories"
            source="categories"
            reference="products/categories"
            sort={{ field: "title", order: "ASC" }}
            filter={{ entry: true }}
            perPage={1000}
            filterToQuery={(val: string) =>
              condition(val) ? { title: val.trim() } : {}
            }
            fullWidth
            allowEmpty
            formClassName={classes.categories}
          >
            <AutocompleteArrayInput optionValue="id" optionText="title" />
          </ReferenceArrayInput>
          <TagReferenceInput
            reference="products/tags"
            source="tags"
            label="Tags"
            perPage={1000}
            allowEmpty
          />
          <NumberInput
            source="minOrder"
            defaultValue={1}
            validate={minValue(1)}
            fullWidth
            formClassName={classes.minOrder}
          />
          {/* <SectionTitle label="resources.products.fieldGroups.relatedProducts" /> */}
          {/* <ReferenceArrayInput
            source="relatedProducts"
            reference="products/products"
            sort={{ field: "title", order: "ASC" }}
            perPage={100}
            filterToQuery={(val: string) =>
              condition(val) ? { name: val.trim() } : {}
            }
            fullWidth
            formClassName={classes.relatedProducts}
          >
            <AutocompleteArrayInput optionText="title" />
          </ReferenceArrayInput> */}
          <BooleanInput
            label="Genuine"
            source="isGenuine"
            name="isGenuine"
            formClassName={classes.boolean}
          />
          <BooleanInput
            label="Local"
            source="isLocal"
            name="isLocal"
            formClassName={classes.boolean}
          />
          <Separator />

          <BooleanInput
            source="backOrder"
            name="backOrder"
            formClassName={classes.boolean}
          />
          <BooleanInput
            label="Reorder Option"
            source="reorderLevel"
            name="reorderLevel"
            formClassName={classes.boolean}
          />
          <Separator />
          <BooleanInput
            label="Bulk Buy Option"
            source="isBulkBuyOpt"
            name="isBulkBuyOpt"
            formClassName={classes.boolean}
          />
          <BooleanInput
            label="Order Sample Option"
            source="isOrderSampleOpt"
            name="isOrderSampleOpt"
            formClassName={classes.boolean}
          />
          <Separator />
          <BooleanInput
            label="Has Custom Logo Opt"
            source="hasCustomLogoOpt"
            name="hasCustomLogoOpt"
            formClassName={classes.boolean}
          />
          <BooleanInput source="isAssured" formClassName={classes.boolean} />
        </FormTab>
        <FormTab label="price">
          <SectionTitle label="resources.products.fieldGroups.shipping" />

          <SelectInput
            source="shipping.class"
            label="Shipping class"
            validate={required()}
            choices={[
              { id: "cost", name: "Cost" },
              { id: "weight", name: "Weight" },
              { id: "sensitivity", name: "Sensitivity" },
              { id: "package", name: "Package" },
            ]}
            formClassName={classes.shippingClass}
          />

          <NumberInput
            source="shipping.value"
            label="Shipping Value"
            validate={required()}
            formClassName={classes.shippingValue}
          />

          <Separator />
          <SectionTitle label="resources.products.fieldGroups.margin" />

          <SelectInput
            source="marginType"
            choices={[
              { id: "global", name: "Global Margin" },
              { id: "percentage", name: "Percentage" },
              { id: "rupees", name: "Rupees" },
            ]}
          />
          <Separator />
          {/* <RenderMarginInput name="margin" /> */}

          <SectionTitle label="resources.products.fieldGroups.price" />
          <NumberInput
            source="setValue"
            helperText="Set if product is in box, packages."
          />
          <BooleanInput
            label="Is Selling Price"
            source="isSp"
            formClassName={classes.boolean}
          />
          <BooleanInput
            label="Hide Price"
            source="hidePrice"
            formClassName={classes.boolean}
          />
          <br />
          <BooleanInput
            label="Have variation"
            source="hasVariation"
            name="hasVariation"
            formClassName={classes.boolean}
          />
          <BooleanInput
            label="Tax included"
            source="isTaxIncluded"
            defaultValue={true}
            formClassName={classes.boolean}
          />
          <RenderPricingNoVariationInput name="price" />
        </FormTab>
        <FormTab label="seo">
          <SectionTitle label="resources.products.fieldGroups.seo" />
          <TextInput
            label="Meta title"
            source="seo.meta_title"
            fullWidth
            formClassName={classes.metaTitle}
          />
          <TextInput
            label="Meta description"
            source="seo.meta_description"
            fullWidth
            formClassName={classes.metaDescription}
          />
          <SeoKeywordInput />
        </FormTab>
        <FormTab label="media">
          <ImageInput
            source="media"
            label="Product pictures"
            accept="image/*"
            placeholder={<p>Drop your image here</p>}
            multiple
          >
            <ProductImageField />
          </ImageInput>
        </FormTab>
        <FormTab label="variation">
          <VariationInfo />
          <VariationInput
            attributeIds={attributeIds}
            attributeData={attributeData}
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

const VariationInfo = (props) => {
  const { values } = useFormState()

  return !values?.hasVariation ? (
    <Typography variant="body1">
      This product doesn't have variation.
    </Typography>
  ) : null
}

export default ProductEdit
