import * as React from "react"
import { FC } from "react"

import CardWithIcon from "./CardWithIcon"

interface Props {
  value?: number | string
  icon?: any
  title: string
  to?: string
  description?: string
}

const MiniCard: FC<Props> = ({ value, icon, title, description, to }) => {
  return (
    <CardWithIcon
      to={to ? to : "/"}
      icon={icon}
      title={title}
      description={description}
      subtitle={value}
    />
  )
}

export default MiniCard
