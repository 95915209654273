import { fetchUtils } from "react-admin"

const apiUrl = "api/vendors"
const httpClient = fetchUtils.fetchJson

const buildFormData = (formData, data, parentKey = "") => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      )
    })
  } else {
    const value = data == null ? "" : data

    formData.append(parentKey, value)
  }
}

const addUploadCapabilities = (dataProvider) => ({
  ...dataProvider,
  create: (resource, params) => {
    if (
      (resource === "products/brands" ||
        resource === "products/categories" ||
        resource === "products/products") &&
      (params.data.media || params.data.image)
    ) {
      let formData = new FormData()

      if (params.data.media) {
        for (let pic of params.data.media) {
          formData.append("media", pic.rawFile)
        }

        delete params.data.media
      }

      if (params.data.image) {
        formData.append("image", params.data.image.rawFile)
        delete params.data.image
      }

      buildFormData(formData, params.data)

      return httpClient(`${apiUrl}/${resource}`, {
        method: "POST",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
    }
    console.log("Fallback route")
    // fallback to the default implementation
    return dataProvider.create(resource, params)
  },
  update: (resource, params) => {
    if (resource === "products/products" && params.data.media) {
      const newPictures = params.data.media.filter(
        (p) => p.rawFile instanceof File
      )
      const formerPictures = params.data.media.filter(
        (p) => !(p.rawFile instanceof File)
      )

      let formData = new FormData()

      newPictures.forEach((pic) => {
        formData.append("media", pic.rawFile)
      })

      formerPictures.forEach((pic) => {
        formData.append("media", pic)
      })

      delete params.data.media

      buildFormData(formData, params.data)

      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "PUT",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
    } else if (
      (resource === "products/brands" || resource === "products/categories") &&
      typeof params.data.image.rawFile !== "undefined"
    ) {
      let formData = new FormData()

      formData.append("image", params.data.image.rawFile)
      delete params.data.image

      buildFormData(formData, params.data)

      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "PUT",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
    }

    // fallback to the default implementation
    return dataProvider.update(resource, params)
  },
})

export default addUploadCapabilities
