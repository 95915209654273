import * as React from "react"
import { Fragment, useState } from "react"
import {
  Button,
  Confirm,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin"
import { green } from "@material-ui/core/colors"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"

const MakePaymentRequestButton = ({ selectedIds }) => {
  const [open, setOpen] = useState(false)
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll()
  const [updateMany, { loading }] = useUpdateMany(
    "sales/sales",
    selectedIds,
    { paymentRequest: { status: true } },
    {
      onSuccess: () => {
        refresh()
        notify("Sales updated")
        unselectAll("sales/sales")
      },
      onFailure: (error) => notify("Error: sales not updated", "warning"),
    }
  )
  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)

  const handleConfirm = () => {
    updateMany()
    setOpen(false)
  }

  return (
    <Fragment>
      <Button
        label="Request for payment"
        onClick={handleClick}
        style={{ color: green[500] }}
      >
        <CheckCircleIcon />
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title="Request for payment"
        content="Are you sure you want to request for payment?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  )
}

export default MakePaymentRequestButton
