import * as React from "react"
import { FC } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  EditProps,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  id: { width: 544 },
  name: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const SaleTitle = ({ record }: any) => <span>Edit Sale #{record.id}</span>

export const SaleEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<SaleTitle />} {...props}>
      <SimpleForm>
        <BooleanInput
          label="Payment status"
          source="paymentStatus.status"
          formClassName={classes.boolean}
        />
        <TextInput source="note" multiline />
      </SimpleForm>
    </Edit>
  )
}
