import React, { useState, useEffect, useCallback } from "react"
import {
  useVersion,
  useDataProvider,
  useTranslate,
  Datagrid,
  List,
  TextField,
  NumberField,
  ReferenceField,
  FunctionField,
  DateField,
  TopToolbar,
} from "react-admin"
import { List as MuiList, ListItem, Card, CardContent } from "@material-ui/core"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CancelIcon from "@material-ui/icons/Cancel"
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn"
import green from "@material-ui/core/colors/green"
import red from "@material-ui/core/colors/red"
import orange from "@material-ui/core/colors/orange"
import { subDays } from "date-fns"

import Welcome from "./Welcome"
import MiniCard from "./MiniCard"
import { Box, Typography } from "@material-ui/core"
import { IDField } from "../common/form"
import MakePaymentRequestButton from "./MakePaymentRequest"
import SaleChart from "./SaleChart"

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
  statsNumber: { marginRight: "0.4em", marginLeft: "0.4em", fontSize: "1.8em" },
}

const Spacer = () => <span style={{ width: "1em" }} />
const VerticalSpacer = () => <span style={{ height: "1em" }} />

interface State {
  users?: number
  newOrders?: number
  vendors?: number
  recentSales?: any
  totalOrdersThisMonth?: number
  totalProducts?: number
  totalSaleAmount?: number
  totalSales?: number
  adminFees?: number
}

const Empty = () => {
  return (
    <Box textAlign="center" m={1}>
      <Typography variant="h4" paragraph>
        You don't have any sales yet.
      </Typography>
      <Typography variant="body1">
        You can request payment once your products are sold
      </Typography>
    </Box>
  )
}

const SalePaymentRequestUpdateActionButtons = (props) => (
  <MakePaymentRequestButton lable="Request payment" {...props} />
)

const ListActions = () => {
  return (
    <TopToolbar>
      <Typography variant="h5">Payments</Typography>
    </TopToolbar>
  )
}

const TopSaleListAction = () => {
  return (
    <TopToolbar>
      <Typography variant="h5">Top Selling Products</Typography>
    </TopToolbar>
  )
}

const Dashboard = ({ permissions }) => {
  const [state, setState] = useState<State>({})
  const version = useVersion()
  const dataProvider = useDataProvider()

  const fetchStats = useCallback(async () => {
    const aMonthAgo = subDays(new Date(), 30)
    const { total: newOrders } = await dataProvider.getList("orders/items", {
      filter: { status: "pending" },
      sort: { field: "updatedAt", order: "ASC" },
      pagination: { perPage: 2, page: 1 },
    })
    const { total: totalOrdersThisMonth } = await dataProvider.getList(
      "orders/items",
      {
        filter: { dateGte: aMonthAgo.toISOString() },
        sort: { field: "createdAt", order: "ASC" },
        pagination: { perPage: 2, page: 1 },
      }
    )
    const { total: totalProducts } = await dataProvider.getList(
      "products/products",
      {
        filter: { "meta.isDeleted": false },
        sort: { field: "updatedAt", order: "ASC" },
        pagination: { perPage: 2, page: 1 },
      }
    )
    const { data: recentSales, total: totalSales } = await dataProvider.getList(
      "sales/sales",
      {
        filter: { dateGte: aMonthAgo.toISOString() },
        sort: { field: "createdAt", order: "DESC" },
        pagination: { page: 1, perPage: 10000 },
      }
    )

    const adminFees = recentSales.reduce(
      (pre: any, curr: any) => pre + curr.margin,
      0
    )

    const totalSaleAmount = recentSales.reduce(
      (pre: any, curr: any) => pre + curr.vendorPayable,
      0
    )

    setState((state) => ({
      ...state,
      newOrders,
      totalProducts,
      recentSales,
      totalOrdersThisMonth,
      totalSaleAmount,
      totalSales,
      adminFees,
    }))
  }, [dataProvider])

  useEffect(() => {
    fetchStats()
  }, [version])

  const {
    newOrders,
    totalProducts,
    recentSales,
    totalOrdersThisMonth,
    totalSales,
    totalSaleAmount,
    adminFees,
  } = state

  const translate = useTranslate()

  return permissions?.roles.includes("ADMIN") ||
    permissions?.roles.includes("VENDOR") ? (
    <div>
      <br />
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <div style={styles.flex}>
            <MiniCard
              title={translate("pos.dashboard.newOrders")}
              icon={ShoppingCartIcon}
              value={`${newOrders ? newOrders : 0}`}
              to="/orders/items"
            />
            <Spacer />
            <MiniCard
              title={translate("pos.dashboard.ordersThisMonth")}
              icon={ShoppingBasketIcon}
              value={totalOrdersThisMonth}
              description="This Month"
              to=""
            />
            <Spacer />
            <MiniCard
              title={translate("pos.dashboard.adminFees")}
              icon={MonetizationOnIcon}
              value={`$ ${adminFees ? adminFees : 0}`}
              to=""
            />
            <Spacer />
            <MiniCard
              title={translate("pos.dashboard.salesAmountThisMonth")}
              icon={MonetizationOnIcon}
              value={`$ ${totalSaleAmount ? totalSaleAmount : 0}`}
              to="sales/sales"
            />
          </div>
        </div>
      </div>

      <br />
      <SaleChart sales={recentSales} style={styles.leftCol} />

      <Box display="flex">
        <Box flex={2} marginRight="0.5em">
          <List
            title="Dashboard"
            pagination={false}
            basePath="/sales/sales"
            resource="sales/sales"
            // filter={{ "paymentRequest.status": false }}
            exporter={false}
            empty={<Empty />}
            bulkActionButtons={<SalePaymentRequestUpdateActionButtons />}
            actions={<ListActions />}
            perPage={5}
          >
            <Datagrid>
              <IDField source="id" />
              <ReferenceField source="productId" reference="products/products">
                <TextField source="title" />
              </ReferenceField>
              <NumberField source="quantity" />
              <FunctionField
                label="Payment receiveable"
                render={(record) =>
                  record.vendorPayable ? (
                    <span style={{ fontWeight: "bold" }}>
                      रू{" "}
                      {record.vendorPayable.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  ) : null
                }
              />
              <FunctionField
                label="Payment Request"
                source="paymentRequest.status"
                render={(record) =>
                  record.paymentRequest && record.paymentRequest.status ? (
                    <CheckCircleIcon htmlColor={green[500]} />
                  ) : (
                    <CancelIcon htmlColor={red[500]} />
                  )
                }
              />
              <DateField
                label="Requested at"
                source="paymentRequest.requestAt"
                showTime
              />
              <FunctionField
                label="Payment Status"
                source="paymentStatus.status"
                render={(record) =>
                  record.paymentStatus && record.paymentStatus.status ? (
                    <CheckCircleIcon htmlColor={green[500]} />
                  ) : (
                    <CancelIcon htmlColor={red[500]} />
                  )
                }
              />
            </Datagrid>
          </List>
        </Box>
        <Box flex={1}>
          <List
            title={<div></div>}
            pagination={false}
            basePath="/sales/topSales"
            resource="sales/topSales"
            // filter={{ "paymentRequest.status": false }}
            exporter={false}
            empty={<Empty />}
            actions={<TopSaleListAction />}
            perPage={5}
          >
            <Datagrid>
              <IDField source="id" />
              <ReferenceField source="id" reference="products/products">
                <TextField source="title" />
              </ReferenceField>
              <NumberField source="salesCount" />
              <FunctionField
                label="Price"
                render={(record) =>
                  record.priceMin && record.priceMax ? (
                    <span style={{ fontWeight: "bold" }}>
                      रू{" "}
                      {record.priceMin == record.priceMax
                        ? record.priceMin.toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : `${record.priceMin} - ${record.priceMax}`}
                    </span>
                  ) : null
                }
              />
            </Datagrid>
          </List>
        </Box>
      </Box>
      <br />
      <Box display="flex">
        <Box flex={2}>
          <Card style={{ maxWidth: 300 }}>
            <CardContent>
              <Typography variant="h5" component="h2">
                Store Stats
              </Typography>
              <MuiList>
                <ListItem>
                  <ShoppingCartIcon htmlColor={green[500]} />
                  <Typography style={styles.statsNumber}>
                    {newOrders}
                  </Typography>
                  <Typography>New Orders</Typography>
                </ListItem>
                <ListItem>
                  <PhotoLibraryIcon htmlColor={red[500]} />
                  <Typography style={styles.statsNumber}>
                    {totalProducts}
                  </Typography>
                  <Typography>Total Products</Typography>
                </ListItem>
                <ListItem>
                  <PhotoLibraryIcon htmlColor={orange[500]} />
                  <Typography style={styles.statsNumber}>
                    {totalSales}
                  </Typography>
                  <Typography>Sold Products</Typography>
                </ListItem>
                <ListItem>Other info...</ListItem>
              </MuiList>
            </CardContent>
          </Card>
        </Box>
        <Box flex={2}></Box>
      </Box>
    </div>
  ) : null
}

export default Dashboard
