import * as React from "react"
import { FC, useState } from "react"
import { useSelector } from "react-redux"
import SettingsIcon from "@material-ui/icons/Settings"
import { useMediaQuery, Theme, Box } from "@material-ui/core"
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  ReduxState,
  usePermissions,
} from "react-admin"
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart"
import ProductIcon from "@material-ui/icons/Collections"

import products from "../resources/products"
import SubMenu from "./SubMenu"
import orderItems from "../resources/orderItems"
import sales from "../resources/sales"

type MenuName = "menuProducts" | "menuOrders"

const Menu: FC<MenuProps> = ({ onMenuClick, logout, dense = false }) => {
  const [state, setState] = useState({
    menuUsers: true,
    menuProducts: true,
    menuOrders: true,
  })
  const translate = useTranslate()
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen)
  //   useSelector((state: AppState) => state.theme) // force rerender on theme change

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }))
  }

  const { permissions } = usePermissions()
  console.log(permissions)

  return (
    <Box mt={1}>
      {permissions?.roles.includes("ADMIN") ||
      permissions?.roles.includes("STAFF") ||
      permissions?.roles.includes("VENDOR") ? (
        <>
          <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
          <SubMenu
            handleToggle={() => handleToggle("menuProducts")}
            isOpen={state.menuProducts}
            sidebarIsOpen={open}
            name="pos.menu.products"
            icon={<ProductIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/products/products`}
              primaryText={translate(`resources.products.name`, {
                smart_count: 2,
              })}
              leftIcon={<products.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle("menuOrders")}
            isOpen={state.menuOrders}
            sidebarIsOpen={open}
            name="pos.menu.orders"
            icon={<AddShoppingCartIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/orders/items?filter={"status":"pending"}`}
              primaryText={translate(`resources.orderItems.name`, {
                smart_count: 2,
              })}
              leftIcon={<orderItems.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/sales/sales`}
              primaryText={translate(`resources.sales.name`, {
                smart_count: 2,
              })}
              leftIcon={<sales.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>
        </>
      ) : null}
      {isXSmall && (
        <MenuItemLink
          to="/configuration"
          primaryText={translate("pos.configuration")}
          leftIcon={<SettingsIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {isXSmall && logout}
    </Box>
  )
}

export default Menu
