import englishMessages from "ra-language-english"

export const messages = {
  simple: {
    action: {
      close: "Close",
      resetViews: "Reset views",
    },
    "create-post": "New post",
  },
  ...englishMessages,
  pos: {
    menu: {
      products: "Products",
      shippings: "Shippings",
      users: "Users",
      orders: "Orders",
      cms: "CMS",
    },
    configuration: "Configurations",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    language: "Language",
    dashboard: {
      welcome: {
        title: "Welcome to Bhotahiti admin dashboard",
        subtitle:
          "A First and only online wholesale bazaar in Nepal. Business to Business wholesale ecommerce platform.",
        ra_button: "Dashboard",
      },
      newOrders: "New Orders",
      users: "Users",
      vendors: "Vendors",
      products: "Products",
      margin: "Margin",
      vendorPayable: "Vendor Payable",
      sales: "Sold Product",
      monthHistory: "30 Days Sales History",
      ordersThisMonth: "Orders (this month)",
      salesAmountThisMonth: "Sales (this month)",
      adminFees: "Admin Fees (this month)",
    },
  },
  resources: {
    posts: {
      name: "Post |||| Posts",
      fields: {
        average_note: "Average note",
        body: "Body",
        comments: "Comments",
        commentable: "Commentable",
        commentable_short: "Com.",
        created_at: "Created at",
        notifications: "Notifications recipients",
        nb_view: "Nb views",
        password: "Password (if protected post)",
        pictures: "Related Pictures",
        published_at: "Published at",
        teaser: "Teaser",
        tags: "Tags",
        title: "Title",
        views: "Views",
        authors: "Authors",
      },
    },
    comments: {
      name: "Comment |||| Comments",
      fields: {
        body: "Body",
        created_at: "Created at",
        post_id: "Posts",
        author: {
          name: "Author",
        },
      },
    },
    users: {
      name: "User |||| Users",
      fieldGroups: {
        information: "Information",
        demographics: "Demographic",
        roles: "Roles",
        options: "Options",
      },
    },
    permissions: {
      name: "Permission |||| Permissions",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    roles: {
      name: "Role |||| Roles",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    attributes: {
      name: "Attribute |||| Attributes",
      fieldGroups: {
        information: "Information",
        values: "Values",
        options: "Options",
      },
    },
    brands: {
      name: "Brand |||| Brands",
      fieldGroups: {
        information: "Information",
        image: "Image",
        options: "Options",
      },
    },
    categories: {
      name: "Category |||| Categories",
      fieldGroups: {
        information: "Information",
        image: "Image",
        options: "Options",
      },
    },
    tags: {
      name: "Tag |||| Tags",
      fieldGroups: {
        information: "Information",
      },
    },
    products: {
      name: "Product |||| Products",
      fieldGroups: {
        attributes: "Attributes",
        main: "Main",
        price: "Price",
        media: "Media",
        information: "Information",
        meta: "Meta",
        options: "Options",
        dimensions: "Dimensions",
        variations: "Variations",
        margin: "Margin",
        seo: "SEO",
        shipping: "Shipping",
      },
    },
    countries: {
      name: "Country |||| Countries",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    states: {
      name: "State |||| States",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    districts: {
      name: "District |||| Districts",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    areas: {
      name: "Area |||| Areas",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    areaCluster: {
      name: "Area Cluster |||| Area Clusters",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    shippingMethods: {
      name: "Shipping Method |||| shippings Methods",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    shippings: {
      name: "Shipping |||| Shippings",
      fieldGroups: {
        information: "Information",
        parameter: "Parameter",
        delivery: "Delivery",
        cost: "Cost",
        options: "Options",
      },
    },
    orders: {
      name: "Order |||| Orders",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    orderItems: {
      name: "Order Item |||| Order Items",
      fieldGroups: {
        information: "Information",
      },
    },
    brandCMS: {
      name: "Brand CMS |||| Brand CMS",
      fieldGroups: {
        information: "Information",
      },
    },
    categoryCMS: {
      name: "Category CMS |||| Category CMS",
      fieldGroups: {
        information: "Information",
      },
    },
    productCMS: {
      name: "Product CMS |||| Product CMS",
      fieldGroups: {
        information: "Information",
      },
    },
    sliderCMS: {
      name: "Slider CMS |||| Slider CMS",
      fieldGroups: {
        information: "Information",
        image: "Image",
        option: "Option",
      },
    },
    menuCMS: {
      name: "Menu CMS |||| Menu CMS",
      fieldGroups: {
        information: "Information",
      },
    },
    pageCMS: {
      name: "Page CMS |||| Page CMS",
      fieldGroups: {
        information: "Information",
        seo: "SEO",
      },
    },
    sales: {
      name: "Sales",
    },
  },
  post: {
    list: {
      search: "Search",
    },
    form: {
      summary: "Summary",
      body: "Body",
      miscellaneous: "Miscellaneous",
      comments: "Comments",
    },
    edit: {
      title: 'Post "%{title}"',
    },
    action: {
      save_and_edit: "Save and Edit",
      save_and_add: "Save and Add",
      save_and_show: "Save and Show",
      save_with_average_note: "Save with Note",
    },
  },
  comment: {
    list: {
      about: "About",
    },
  },
  user: {
    list: {
      search: "Search",
    },
    form: {
      summary: "Summary",
      security: "Security",
    },
    edit: {
      title: 'User "%{title}"',
    },
    action: {
      save_and_add: "Save and Add",
      save_and_show: "Save and Show",
    },
  },
  product: {
    action: {
      save_and_add: "Save and Add",
      save_and_show: "Save and Show",
    },
  },
}

export default messages
