import * as React from "react"
import { FC, useState } from "react"
import {
  List,
  Datagrid,
  BooleanField,
  SingleFieldList,
  TextField,
  EditButton,
  BooleanInput,
  Filter,
  SearchInput,
  DateField,
  DeleteButton,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  useRecordContext,
  FilterProps,
  BulkDeleteButton,
} from "react-admin"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"

import ListActions from "../../common/listActions"
import { IDField, Separator } from "../../common/form"
import BrandField from "./BrandField"
import CategoryField from "./CategoryField"
import { ListProps } from "@material-ui/core"
import MakeProductOutOfStockButton from "./MakeProductOutOfStockButton"
import rowStyle from "./rowStyle"
import MakeProductInStockButton from "./MakeProductInStockButton"

const ProductFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <BooleanInput label="Visible" source="isVisible" />
  </Filter>
)

const MyImageField = ({ record }: any) => {
  return (
    <div>
      <img
        src={
          record.media && record.media.length > 0
            ? `${process.env.REACT_APP_AWS_BUCKET_URL}${record.media[0]}`
            : ""
        }
        height={75}
        width="auto"
      />
    </div>
  )
}

const ProductStockUpdateActionButtons = (props) => (
  <>
    <MakeProductInStockButton label="Make in stock" {...props} />
    <MakeProductOutOfStockButton label="Make out of stock" {...props} />
    <BulkDeleteButton {...props} />
  </>
)

const ProductList: FC<ListProps> = (props) => {
  console.log(props)
  const [deletefilter, setdeletefilter] = useState(false)

  const updateDeleteFilter = () => {
    setdeletefilter(!deletefilter)
  }
  return (
    <List
      {...props}
      bulkActionButtons={<ProductStockUpdateActionButtons />}
      filter={{ "meta.isDeleted": deletefilter }}
      filters={<ProductFilter />}
      sort={{ field: "updatedAt", order: "DESC" }}
      // perPage={20}
      actions={
        <ListActions
          deletefilter={deletefilter}
          updatedeletefilter={updateDeleteFilter}
        />
      }
    >
      <Datagrid rowStyle={rowStyle}>
        <IDField source="id" sortable={false} />
        <TextField label="Title" source="title" />
        <MyImageField source="media" />
        <ReferenceField source="brand" reference="products/brands">
          <BrandField />
        </ReferenceField>
        <ReferenceArrayField
          source="categories"
          reference="products/categories"
        >
          <SingleFieldList>
            <CategoryField />
          </SingleFieldList>
        </ReferenceArrayField>
        <NumberField source="minOrder" />
        <NumberField source="stock" />
        <BooleanField
          label="Visible"
          source="meta.isVisible"
          TrueIcon={VisibilityIcon}
          FalseIcon={VisibilityOffIcon}
        />
        <DateField source="updatedAt" />
        <EditButton />
        {!deletefilter ? <DeleteButton /> : <></>}
      </Datagrid>
    </List>
  )
}

export default ProductList
