import * as React from "react"
import { Admin, Resource, DataProvider } from "react-admin"
import polyglotI18nProvider from "ra-i18n-polyglot"

import themeReducer from "./redux/themeReducer"
import { Layout, Login } from "./layout"
import englishMessages from "./i18n/en"
import products from "./resources/products"
import customRoutes from "./routes"
import authProvider from "./authProvider"
import { Dashboard } from "./dashboard"
import orderItems from "./resources/orderItems"
import sales from "./resources/sales"

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default)
  }

  // Always fallback on english
  return englishMessages
}, "en")
interface AppProps {
  dataProvider: DataProvider
}

const App = ({ dataProvider }: AppProps) => {
  return (
    <Admin
      title=""
      // @ts-ignore
      authProvider={authProvider}
      dataProvider={dataProvider}
      dashboard={Dashboard}
      customReducers={{ theme: themeReducer }}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
      customRoutes={customRoutes}
      disableTelemetry
    >
      <Resource name="products/attributes" />
      <Resource name="products/brands" />
      <Resource name="products/categories" />
      <Resource
        name="products/products"
        options={{ label: "Products" }}
        {...products}
      />
      <Resource name="products/variations" />
      <Resource name="products/tags" />
      <Resource name="orders/items" {...orderItems} />
      <Resource name="sales/sales" options={{ label: "Sales" }} {...sales} />
      <Resource name="sales/topSales" />
    </Admin>
  )
}

export default App
