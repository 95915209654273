import * as React from "react"
import { FC } from "react"
import {
  List,
  Datagrid,
  BooleanField,
  TextField,
  DateField,
  Filter,
  BooleanInput,
  ReferenceField,
  FieldProps,
  NumberField,
  FilterProps,
  FunctionField,
} from "react-admin"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CancelIcon from "@material-ui/icons/Cancel"

import { IDField } from "../../common/form"
import { ListProps } from "@material-ui/core"
import MakePaymentRequestButton from "../../dashboard/MakePaymentRequest"

const SaleFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <BooleanInput label="Payment Status" source="paymentStatus.status" />
    <BooleanInput label="Request for payment" source="paymentRequest.status" />
  </Filter>
)

const SalePaymentUpdateActionButtons = (props) => (
  <MakePaymentRequestButton lable="Make payment" {...props} />
)

interface VariationFieldProps extends FieldProps {
  record?: any
}

const VariationField: FC<VariationFieldProps> = ({ record }) => {
  return record.hasVariation ? (
    <div style={{ minWidth: "200px" }}>
      {record.variation.map((variation) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <p
              style={{
                fontWeight: "bold",
                maxWidth: "200px",
                overflow: "hidden",
              }}
            >
              {variation.title}
            </p>
          </div>
          <div>
            <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
              Quantity
            </span>
            : {variation.quantity},
            <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
              price
            </span>
            : {variation.price}
          </div>
          <div>
            <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
              Margin
            </span>
            : {variation.margin},
          </div>
          <div>
            <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
              Vendor payable
            </span>
            : {variation.vendorPayable}
          </div>
        </div>
      ))}
    </div>
  ) : (
    <span></span>
  )
}

interface PricingFieldProps extends FieldProps {
  record?: any
}

const PricingField: FC<PricingFieldProps> = ({ record }) => {
  return (
    record && (
      <span style={{ fontWeight: "bold" }}>
        रू{" "}
        {(record.margin + record.vendorPayable).toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </span>
    )
  )
}

export const SaleList: FC<ListProps> = (props) => {
  console.log(props)
  return (
    <List
      {...props}
      bulkActionButtons={<SalePaymentUpdateActionButtons />}
      filter={{ "meta.isDeleted": false }}
      filters={<SaleFilter />}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <Datagrid>
        <IDField sortable={false} source="id" />
        <DateField source="createdAt" label="Sales at" showTime />
        <BooleanField
          label="Request for payment"
          source="paymentRequest.status"
          TrueIcon={CheckCircleIcon}
          FalseIcon={CancelIcon}
        />
        <DateField
          label="Request at"
          source="paymentRequest.requestAt"
          showTime
        />
        <BooleanField
          source="paymentStatus.status"
          label="Paid"
          TrueIcon={CheckCircleIcon}
          FalseIcon={CancelIcon}
        />
        <DateField label="Paid at" source="paymentStatus.paidAt" showTime />
        <ReferenceField source="productId" reference="products/products">
          <TextField source="title" />
        </ReferenceField>
        <NumberField source="quantity" />
        <FunctionField
          label="Receivable Amounts"
          render={(record) =>
            record.vendorPayable ? (
              <span style={{ fontWeight: "bold" }}>
                रू{" "}
                {record.vendorPayable.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            ) : null
          }
        />
      </Datagrid>
    </List>
  )
}
